import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CardButton from '../card-button';
import { ENUM_ORDER_DELIVERY_TYPE, ENUM_STATUS } from '../../helpers/enums';
import { STORAGE_KEYS } from '../../helpers/constants';
import CardHeaderTag from './cardHeaderTag';
import CardHeaderDelivery from './cardHeaderDelivery';
import ItemTypeTags from './roleTag';
import moment from 'moment';
import {
  getNameFromOrder,
  numberWithCommas,
  orderIsPinned,
  showPinnedFilter
} from '../../helpers/functions';
import useLayoutContext from '../../contexts/layout.context';
import { useLocation } from 'react-router';
import ChowdeckLogo from '../../assets/images/chowdeck.png';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const CardHeader = observer(function CardHeader({
  order,
  calculatedOrder,
  paid,
  walletAmount,
  isGroupOrder,
  status,
  actionLoading,
  actionDisabled,
  packagingComplete,
  actionClick,
  receiptRef,
  setShowInfoModal,
  hideCardButton,
  disableReceipt
}) {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const hideButton =
    hideCardButton || (role.includes('ADMIN') && status === ENUM_STATUS.PENDING && !paid);
  const [showDeliveryState, setShowDeliveryState] = useState(false);

  const packagingStationRoles = [
    ENUM_KITCHEN_ROLE.PACKAGING,
    ENUM_KITCHEN_ROLE.KITCHEN_ADMIN,
    ENUM_KITCHEN_ROLE.SUPER_ADMIN,
    ENUM_KITCHEN_ROLE.FRONT_DESK,
    ENUM_KITCHEN_ROLE.KITCHEN_STAFF
  ];

  useEffect(() => {
    const pathname = window.location.pathname;
    const isPackagingLiveOrders = role === ENUM_KITCHEN_ROLE.PACKAGING && pathname === '/packaging';
    const isKitchen =
      (role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF || ENUM_KITCHEN_ROLE.KITCHEN_ADMIN) &&
      pathname === '/kitchen';
    const isShop = role === ENUM_KITCHEN_ROLE.SHOP_STAFF;
    const isKitchenAdmin = role === ENUM_KITCHEN_ROLE.KITCHEN_ADMIN;
    setShowDeliveryState(isPackagingLiveOrders || isShop || isKitchen || isKitchenAdmin);
  }, []);
  const { pinnedOrders, setPinnedOrders } = useLayoutContext();

  const getScheduledTime = () => {
    const time = moment(order.kitchen_start_order);
    const isToday = time.isSame(new Date(), 'day');
    return isToday ? time.format('hh:mm A') : time.format('Do MMM, hh:mm A');
  };

  const getEndScheduledTime = () => {
    const endTime = moment(order.kitchen_start_order).add(
      order.kitchen_preparation_time_in_minutes ?? 45,
      'minutes'
    );
    const isToday = endTime.isSame(new Date(), 'day');
    return isToday ? endTime.format('hh:mm A') : endTime.format('Do MMM, hh:mm A');
  };

  const isFrontDeskUnpaidPage = () => {
    const path = location.pathname;
    return path === '/front-desk/unpaid' && order.scheduled;
  };

  const showScheduledTime = () => {
    return (
      (order.scheduled || order.preorder) &&
      [ENUM_KITCHEN_ROLE.PACKAGING, ENUM_KITCHEN_ROLE.KITCHEN_STAFF].includes(role)
    );
  };

  const filterPinnedOrders = () => {
    const orderIndex = pinnedOrders?.findIndex((pinnedOrder) => pinnedOrder?.id === order?.id);
    let pinned = [...pinnedOrders];
    if (orderIndex !== -1) {
      pinned.splice(orderIndex, 1);
    } else {
      pinned = [...pinned, order];
    }
    setPinnedOrders(pinned);
  };
  const { pathname } = useLocation();
  const QUICK_EAT = 'quick eat';
  const isQuickEats = (order) => {
    const brand_ids = order.calculated_order?.brand_ids ?? [];
    const brand_names = brand_ids.map((brand) => brand.name.toLowerCase());
    return brand_names.some((name) => name.includes(QUICK_EAT));
  };

  return (
    <div className="min-h-[70px]">
      {showDeliveryState && <CardHeaderDelivery box_number={order.box_number} />}
      <div className="w-full flex justify-between items-end mb-1 order-card-header ">
        <div className="flex flex-col">
          <div className="flex gap-2">
            {showPinnedFilter(pathname) && (
              <div>
                <span
                  className="cursor-pointer text-sm bg-white text-green-5 font-medium mb-4 py-[2px] px-[6px] rounded-2xl"
                  onClick={filterPinnedOrders}>
                  📌 {orderIsPinned(pinnedOrders, order) ? 'Unpin' : 'Pin'}
                </span>
              </div>
            )}
            {calculatedOrder?.user?.loyal_customer && (
              <CardHeaderTag type="loyal" className="ml-0" />
            )}
          </div>
          {order.delivery === ENUM_ORDER_DELIVERY_TYPE.CHOWDECK && (
            <div>
              <img src={ChowdeckLogo} alt="chowdeck-logo" className="object-cover mb-1" />
            </div>
          )}
          {isQuickEats(order) && (
            <div className="px-4 py-1 bg-white rounded-full mt-2">Quick Eats</div>
          )}
          <h6
            onClick={() => setShowInfoModal(true)}
            className="cursor-pointer self-start mb-0 text-white">
            <div className="flex justify-start items-center text-white">
              <span className="text-[20px] font-medium uppercase">{order.order_code}</span>
              <ItemTypeTags order={order} />

              {!isGroupOrder && order?.first_order && <CardHeaderTag type="new" />}
            </div>

            <div>
              <div className="flex justify-start items-center">
                {!isGroupOrder && <p className="font-medium">{getNameFromOrder(order)}</p>}
                {order.kitchen_time_elapsed && status !== ENUM_STATUS.REJECTED && (
                  <CardHeaderTag type="late" />
                )}
              </div>
              {(showScheduledTime() || isFrontDeskUnpaidPage()) && (
                <div className="flex flex-col gap-2 my-2">
                  <p>
                    Kitchen start time:
                    <span className="whitespace-nowrap bg-white bg-opacity-30 rounded-md ml-1 text-xs py-1 px-2">
                      {getScheduledTime()}
                    </span>
                  </p>
                  <p>
                    Kitchen prep duration:
                    <span className="whitespace-nowrap bg-white bg-opacity-30 rounded-md ml-1 text-xs py-1 px-2">
                      {order.kitchen_preparation_time_in_minutes ?? 45} mins
                    </span>
                  </p>
                  <p>
                    Kitchen end time:
                    <span className="whitespace-nowrap bg-white bg-opacity-30 rounded-md ml-1 text-xs py-1 px-2">
                      {getEndScheduledTime()}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </h6>
        </div>

        <div className="flex flex-col justify-start items-end">
          {packagingStationRoles?.includes(role) && order?.packaging_split_screen ? (
            <p className="text-sm text-white font-medium mb-4">
              {order?.packaging_split_screen?.name}
            </p>
          ) : null}
          {!hideButton && (
            <CardButton
              actionDisabled={actionDisabled}
              status={status}
              paid={paid}
              packagingComplete={packagingComplete}
              actionClick={actionClick}
              actionLoading={actionLoading}
              receiptRef={receiptRef}
              isPickup={calculatedOrder?.pickup}
              order={order}
              disableReceipt={disableReceipt}
            />
          )}
          {!showScheduledTime() && (
            <div className="text-right mt-[4px] mb-5 text-white">
              {!paid && walletAmount > 0 && (
                <p className="mb-1">
                  Amount paid by wallet: <strong> ₦{numberWithCommas(+walletAmount)}</strong>
                </p>
              )}
              {!paid && order.transfer_amount !== '0' && (
                <p className="mb-1">
                  Amount paid by auto transfer:
                  <strong>₦{numberWithCommas(+order.transfer_amount)}</strong>
                </p>
              )}
              {!paid && (
                <p>
                  Amount due:
                  <strong>
                    ₦
                    {numberWithCommas(
                      +calculatedOrder?.total_amount - +walletAmount - +order.transfer_amount
                    )}
                  </strong>
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default CardHeader;
