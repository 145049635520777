export enum ENUM_ITEM_TYPES {
  FOOD = 'FOOD',
  SHOP = 'SHOP',
  ALL = 'ALL'
}

export enum ENUM_STATUS {
  READY = 'ready',
  STARTED = 'started',
  PENDING = 'pending',
  PACKAGING = 'is_packaging',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
  DISPATCH = 'dispatch',
  SEARCH = 'search'
}

export enum ENUM_PAGE {
  LIVE = 'live'
}

export enum ENUM_CARDS_TYPES {
  LIVE = 'LIVE',
  RECALL = 'RECALL',
  DISPATCH = 'DISPATCH'
}

export enum ENUM_LAYOUT_STATE {
  LOADING,
  WAITING,
  EMPTY_SEARCH,
  PAGE
}

export enum ENUM_ORDER_DELIVERY_TYPE {
  FOODCOURT = 'foodcourt',
  CHOWDECK = 'chowdeck'
}
export enum ENUM_PAGES {
  DISPATCH = 'dispatch',
  RECALLED = 'recalled',
  SCHEDULED = 'scheduled',
  LIVE = 'live',
  UNPAID = 'unpaid'
}
